@import "/src/common/colors";

.b-dropdown {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  // gap: 10px;
  align-items: flex-start;
  width: 150px;

  a {
    text-decoration: none;
  }

  .b-dropdown-toggle {
    height: 20px;
    width: 20px;
    padding-top: 1.5px;
    stroke-width: 2.6;
    viewport-fit: space;
    transform: scale(1.5);
  }

  .b-dropdown-items-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;

    .b-dropdown-item {
      line-height: 1;
      transition: all 0.2s;

      &.selected,
      &:hover {
        font-weight: 600;
      }
    }
  }
}
