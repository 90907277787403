.b-blog-posts-container {
  // background-image: url("../../common/bg.svg");
  // background-repeat: no-repeat;
  // background-size: cover;
  padding-top: 180px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  gap: 30px;
}
