.b-blog-view-page-container {
  min-height: 100vh;
  transition: all 0.2s;
}

.theme-toggle {
  cursor: pointer;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0 6px;
  width: 36px;
  height: 36px;
  right: 20px;
  bottom: 20px;
  transition: 0.5s;

  &.shifted {
    // bottom: 65px;
  }
}

.scroll-to-top {
  cursor: pointer;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0 6px;
  width: 36px;
  height: 36px;
  right: 20px;
  bottom: 65px;
  transition: 0.5s;

  &.hidden {
    right: -80px;
  }
}