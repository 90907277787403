@import "src/common/colors";

.b-navbar-container {
  position: fixed;
  top: 10px;
  width: 100%;
  z-index: 999;
  padding: 0 20px;
  transition: 0.5s;
  // mix-blend-mode: overlay;

  &.hidden {
    top: -100px;
    opacity: 0;
  }

  .b-navbar {
    margin: 10px auto;
    width: 80px;
    height: 80px;
    border-radius: 75px;
    display: flex;
    align-items: flex-start;
    padding: 20px 28px;
    transition: all 0.6s;

    &.active {
      width: 100%;
      padding-left: 35px;
      padding-right: 20px;
    }

    &.no-bg {
      background-color: transparent;
    }

    .b-navbar-logo {
      cursor: pointer;
      margin-top: 2px;
      font-family: "Prata";
      // color: $b-olive-darker;
      font-size: 40px;
      line-height: 1;
      text-align: center;
      transition: opacity 0.6s;
    }

    .b-navbar-items-container {
      display: flex;
      margin-top: 5px;
      margin-left: auto;
      margin-right: 15px;
      gap: 20px;

      .b-navbar-item {
        // color: $b-olive-darker;
        font-size: 20px;
        transition: font-weight 0.1s;
        width: 60px;
        text-align: end;
        &.selected {
          font-weight: 600;
        }
      }
    }

    .b-navbar-items-dropdown {
      padding: 10px 15px;
      border-radius: 21px;
      transition: opacity 0.6s;
      min-height: 42px;

      .b-navbar-dropdown-item {
        font-size: 20px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .hidden {
    opacity: 0;
  }

  .zero-width {
    width: 0;
  }
}

@media only screen and (max-width: 768px) {
  .b-navbar-container {
    padding: 0 10px;
  }
}
