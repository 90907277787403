@font-face {
  font-family: 'Lugon';
  src: url('./Lugon/LugonRegular.woff2') format('woff2'),
      url('./Lugon/LugonRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenue';
  src: url('./Avenue/MADEAvenuePERSONALUSE-Regular.woff2') format('woff2'),
      url('./Avenue/MADEAvenuePERSONALUSE-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

