@import "src/common/colors";

.b-title {
  color: $b-yellow;
  font-family: "Prata", serif;
  font-size: 42px;
  line-height: 1;
}

.b-masthead {
  padding: 0 48px;
  // background-color: $b-tan;
  min-height: 100vh;
  // min-height: -webkit-fill-available;
  color: $b-tan-darker;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 20px;

  .b-masthead-title {
    font-family: "Poppins", serif;
    font-weight: 600;
    font-size: 80px;
    transition: all 0.5s;
    line-height: 1;
    margin-bottom: 20px;
  }

  .b-masthead-subtitle {
    font-family: "Poppins", serif;
    font-size: 42px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 1200px) {
  .b-masthead {
    padding: 0 24px;

    .b-masthead-title {
      font-size: 64px;
    }

    .b-masthead-subtitle {
      font-size: 36px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .b-masthead {
    .b-masthead-title {
      font-size: 56px;
    }

    .b-masthead-subtitle {
      font-size: 28px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .b-masthead {
    .b-masthead-title {
      font-size: 48px;
    }

    .b-masthead-subtitle {
      font-size: 22px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .b-masthead {
    padding: 0 12px;

    .b-masthead-title {
      font-size: 36px;
    }
  }
}
