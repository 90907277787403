@import "src/common/colors";

.b-contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

@media only screen and (max-width: 991px) {
  .b-contact {
    flex-direction: row;
  } 
}