@import "/src/common/colors";

.b-blog-post-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  gap: 10px;

  .b-blog-post-card-img {
    height: 100px;
    width: 100px;
    overflow: hidden;
    object-fit: cover;
  }

  .b-blog-post-card-title {
    font-family: "Prata", serif;
    font-size: 42px;
    line-height: 1.2;
  }

  .b-blog-post-card-tags-container {
    display: flex;
    gap: 10px 30px;
    flex-wrap: wrap;

    .b-blog-post-card-tag {
      font-size: 20px;
      text-decoration: underline;
      white-space: nowrap;
    }
  }

  .b-blog-post-card-date {
    font-size: 20px;
    white-space: nowrap;
  }

  &.yellow {
    color: $b-text-yellow;
  }

  &.olive {
    color: $b-text-olive;
  }

  &.pink {
    color: $b-text-pink;
  }

  &.black {
    color: #000;
  }

  &.white {
    color: #fff;
  }

  &.red {
    color: $b-text-red;
  }

  &.blue {
    color: $b-text-blue;
  }

  &.cyan {
    color: $b-text-cyan;
  }

  @media only screen and (max-width: 1200px) {
    .b-blog-post-card-title {
      font-size: 36px;
    }
  }

  @media only screen and (max-width: 942px) {
    .b-blog-post-card-title {
      font-size: 32px;
    }
  }

  @media only screen and (max-width: 768px) {
    .b-blog-post-card-title {
      font-size: 28px;
    }
  }

  @media only screen and (max-width: 576px) {
    .b-blog-post-card-title {
      font-size: 24px;
    }
  }

  &.sm {
    .b-blog-post-card-title {
      font-size: 20px !important;
    }

    .b-blog-post-card-tags-container {
      .b-blog-post-card-tag {
        font-size: 16px !important;
      }
    }

    .b-blog-post-card-date {
      font-size: 16px !important;
    }
  }

  &.md {  
    .b-blog-post-card-title {
      font-size: 24px !important;
    }

    .b-blog-post-card-tags-container {
      .b-blog-post-card-tag {
        font-size: 18px !important;
      }
    }

    .b-blog-post-card-date {
      font-size: 18px !important;
    }
  }

  &.lg {
    .b-blog-post-card-title {
      font-size: 30px !important;
    }

    .b-blog-post-card-tags-container {
      .b-blog-post-card-tag {
        font-size: 24px !important;
      }
    }

    .b-blog-post-card-date {
      font-size: 24px !important;
    }
  }
}
