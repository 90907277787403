@import "./common/colors";

body {
  margin: 0;
  background-color: $b-tan;
  // min-height: 100vh;
  // @supports (-webkit-touch-callout: none) {
  //   & {
  //     min-height: -webkit-fill-available !important;
  //   }
  // }
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// .home-bg {
//   position: fixed;
//   z-index: -1;
//   right: 0;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   background-color: $b-tan;
//   background-image: url("./common/bg.jpg");
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   transition: all 1s;
//   opacity: 1;
//   filter: saturate(1.7) brightness(2);
//   -webkit-filter: saturate(1.7) brightness(1.2) blur(3px);
//   mix-blend-mode: normal;

//   &.hidden {
//     transition: all 0.3s;
//     opacity: 0.5;
//   }
// }

.works-bg {
  background-color: $b-tan;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.nostyle-anchor:link,
a.nostyle-anchor:visited {
  text-decoration: inherit;
  color: inherit;
}

.disabled {
  opacity: 0.5;
  filter: grayscale(0.8);
}

.b-bloom {
  box-shadow: 0 0 20vh 20vh $b-yellow;
  background-color: rgba($b-yellow, 0.9);
  height: 5px;
  width: 5px;
  border-radius: 5px;
}

.b-chip {
  display: flex;
  padding: 3px 7px;
  border: 1px solid $b-olive-darker;
  border-radius: 100px;
  color: $b-olive-darker;
  font-size: 12px;
  white-space: nowrap;
  width: fit-content;
  height: fit-content;
}

.serif {
  font-family: "Prata", serif;
}

.underline {
  text-decoration: underline !important;
}

.bg-pink-lighter {
  background-color: $b-pink-lighter;
}

.bg-pink {
  background-color: $b-pink;
}

.text-pink {
  color: $b-pink-darker;
}

.border-pink {
  border: 1px solid $b-pink-darker;
  border-radius: 40px;
}

.shadow-pink {
  box-shadow: 0 0 5px $b-pink-darker;
}

.hover-shadow-pink {
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 0 15px rgba($b-pink-darker, 0.4);
  }
}

.text-shadow-pink {
  text-shadow: 0 0 6px rgba($b-pink-darker, 0.5);
}

.bg-yellow-lighter {
  background-color: $b-yellow-lighter;
}

.bg-yellow {
  background-color: $b-yellow;
}

.text-yellow {
  color: $b-yellow-darker;
}

.border-yellow {
  border: 1px solid $b-yellow-darker;
  border-radius: 40px;
}

.shadow-yellow {
  box-shadow: 0 0 5px $b-yellow-darker;
}

.hover-shadow-yellow {
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 0 15px rgba($b-yellow-darker, 0.4);
  }
}

.text-shadow-yellow {
  text-shadow: 0 0 6px rgba($b-yellow-darker, 0.5);
}

.bg-olive-lighter {
  background-color: $b-olive-lighter;
}

.bg-olive {
  background-color: $b-olive;
}

.text-olive {
  color: $b-olive-darker;
}

.border-olive {
  border: 1px solid $b-olive-darker;
  border-radius: 40px;
}

.shadow-olive {
  box-shadow: 0 0 5px $b-olive-darker;
}

.hover-shadow-olive {
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 0 15px rgba($b-olive-darker, 0.4);
  }
}

.text-shadow-olive {
  text-shadow: 0 0 6px rgba($b-olive-darker, 0.5);
}

.bg-lime-lighter {
  background-color: $b-lime-lighter;
}

.bg-lime {
  background-color: $b-lime;
}

.text-lime {
  color: $b-lime-darker;
}

.border-lime {
  border: 1px solid $b-lime-darker;
  border-radius: 40px;
}

.shadow-lime {
  box-shadow: 0 0 5px $b-lime-darker;
}

.hover-shadow-lime {
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 0 15px rgba($b-lime-darker, 0.4);
  }
}

.text-shadow-lime {
  text-shadow: 0 0 6px rgba($b-lime-darker, 0.5);
}

.bg-peach-lighter {
  background-color: $b-peach-lighter;
}

.bg-peach {
  background-color: $b-peach;
}

.text-peach {
  color: $b-peach-darker;
}

.border-peach {
  border: 1px solid $b-peach-darker;
  border-radius: 40px;
}

.shadow-peach {
  box-shadow: 0 0 5px $b-peach-darker;
}

.hover-shadow-peach {
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 0 15px rgba($b-peach-darker, 0.4);
  }
}

.text-shadow-peach {
  text-shadow: 0 0 6px rgba($b-peach-darker, 0.5);
}

.bg-tan-lighter {
  background-color: $b-tan-lighter;
}

.bg-tan {
  background-color: $b-tan;
}

.text-tan {
  color: $b-tan-darker;
}

.border-tan {
  border: 1px solid $b-tan-darker;
  border-radius: 40px;
}

.shadow-tan {
  box-shadow: 0 0 5px $b-tan-darker;
}

.hover-shadow-tan {
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 0 15px rgba($b-tan-darker, 0.4);
  }
}

.text-shadow-tan {
  text-shadow: 0 0 6px rgba($b-tan-darker, 0.5);
}

.bg-blue-lighter {
  background-color: $b-blue-lighter;
}

.bg-blue {
  background-color: $b-blue;
}

.text-blue {
  color: $b-blue-darker;
}

.border-blue {
  border: 1px solid $b-blue-darker;
  border-radius: 40px;
}

.shadow-blue {
  box-shadow: 0 0 5px $b-blue-darker;
}

.hover-shadow-blue {
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 0 15px rgba($b-blue-darker, 0.4);
  }
}

.text-shadow-blue {
  text-shadow: 0 0 6px rgba($b-blue-darker, 0.5);
}

.bg-snow-lighter {
  background-color: $b-snow-lighter;
}

.bg-snow {
  background-color: $b-snow;
}

.text-snow {
  color: $b-snow-darker;
}

.border-snow {
  border: 1px solid $b-snow-darker;
  border-radius: 40px;
}

.shadow-snow {
  box-shadow: 0 0 5px $b-snow-darker;
}

.hover-shadow-snow {
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 0 15px rgba($b-snow-darker, 0.4);
  }
}

.text-shadow-snow {
  text-shadow: 0 0 6px rgba($b-snow-darker, 0.5);
}

.bg-white {
  background-color: #fff;
}

.border-black {
  border: 1px solid #000;
  border-radius: 40px;
}
