@import "/src/common/colors";

.b-blog-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  // color: $b-text-pink;
  // max-width: 1000px;
  font-weight: 500;
  transition: all 0.2s;

  // .b-blog-view-image {
  //   border-radius: 10px;
  // }

  .b-blog-view-table {
    display: flex;
    gap: 50px;
    padding: 20px 0;
    justify-content: space-evenly;
    .b-blog-view-table-item {
      max-width: 300px;
      // height: fit-content;
      .b-blog-view-table-item-content {
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }

  img {
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Prata", serif;
  }

  h1,
  h2,
  h3 {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  p,
  ul,
  ol {
    margin-bottom: 30px;
  }

  table td,
  table td * {
    vertical-align: top;
  }

  table {
    margin: 0 auto;

    th {
      margin-right: 20px;
    }

    td {
      padding-right: 30px;
      max-width: 300px;
      align-items: flex-start;
    }
  }
}

@media only screen and (max-width: 768px) {
  .b-blog-view {
    // padding: 10px 0;
    .b-blog-view-table {
      flex-direction: column;
      gap: 15px;
      .b-blog-view-table-item {
        max-width: 100%;
      }
    }
  }
}
