$b-pink-lighter: #ffefef;
$b-pink: #edc5c5;
$b-pink-darker: #98375e;

$b-olive-lighter: #edf9e2;
$b-olive: #c4d3b5;
$b-olive-darker: #40532e;

$b-lime-lighter: #eafce5;
$b-lime: #d1f9c6;
$b-lime-darker: #415449;

$b-yellow-lighter: #fff2d4;
$b-yellow: #fbe6b4;
$b-yellow-darker: #805a00;

$b-peach-lighter: #ffece3;
$b-peach: #fbac8b;
$b-peach-darker: #a33326;

$b-tan-lighter: #fff6dc;
$b-tan: #fcf0cd;
$b-tan-darker: #c85d15;

$b-blue-lighter: #f8faff;
$b-blue: #b9daff;
$b-blue-darker: #074c88;

$b-snow-lighter: #f8f8fb;
$b-snow: #f2f8ff;
$b-snow-darker: #222222;

// these are deprecatedd
$b-text-pink: #98375e;
$b-text-pink-darker: #42172c;
$b-text-pink-darkest: #2d111e;
$b-text-olive: #69963b;
$b-text-yellow: #f8ed73;
$b-text-blue: #213fae;
$b-text-red: #b90025;
$b-text-cyan: #0a8f94;